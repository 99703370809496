<template>
  <v-container class="mt-5 main-content" >
    <div class="Title" >
      <h1 class="text-h5">  ナビゲーション 
        <v-btn icon @click="slide=true;"><v-icon>{{icons.informationOutline}}</v-icon></v-btn>
        </h1>
    </div>

    <NaviTable /> 
    <v-dialog 
      v-model="slide"
      max-width="400px" 
      width="90%"
      style="z-index:2001"
    >
      <CarouselTutorial
        type="navi"
        v-on:closeEmit="slide=false"
      />

      <!-- <v-btn   color="blue" class="white--text mt-1"  @click="slide=false;">Close</v-btn> -->
    </v-dialog>


  </v-container>
</template>

<script>
  
const NaviTable = () => import('@/components/NaviTable.vue');
const CarouselTutorial = () => import('@/components/CarouselTutorial.vue');
import { mdiInformationOutline } from '@mdi/js'

  export default {
    name: 'Navi',
    components: {
      NaviTable,
      CarouselTutorial
    },
    data: () => ({
      icons:{
        "informationOutline":mdiInformationOutline
      },
      slide:false,
    }),
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 400;
    border-bottom: 1px solid gray;
  }

  
.v-application{
  font-family: 'Noto Sans JP', sans-serif;
}

.application {
  font-family: 'Noto Sans JP', sans-serif;
}

</style>
